<template>
  <UserProfile
      @deleted="handleDelete"
      :propped-user="user"
      :tabs="[
              {title: 'User Details', role: 'details'},
              {title: 'Profile', role: 'profile'},
              {title: 'Enrolments', role: 'enrolments'},
              {title: 'Guardians', role: 'guardians'},
              {title: 'Services', role: 'services'},
              {title: 'Notes', role: 'notes'},
     ...(!!$store.getters['entities/user-permissions/find']('view media') ? [{
            title: 'Media',
            role: 'media'
          }] : []),              {title: 'Observations', role: 'observations'},
              {title: 'Activities', role: 'activities'},
              {title: 'Reports', role: 'reports'},
              {title: 'Sports House', role: 'sports-house'},
              {title:'Incidents',role:'incidents'},
              {title:'Covid Assessments',role: 'covid-assessments'},

            ]"
      :profile-fields="[
      'date_of_birth',
      'id',
      'gender',
      'language',
      'cemis_number',
      'school',
      'grade',
      'dominant_hand',
      'ethnicity',
      'medical_information',
      'siblings',
      'consent','lunch','day_length',
      'emergency_contact',
      'home_address','religion'
    ]"
      v-if="selected"
      :type="'Learner'"
      :canCreateEnrolment="
      !!$store.getters['entities/user-permissions/find']('create enrolments')
    "
      :canDeleteEnrolment="
      !!$store.getters['entities/user-permissions/find']('delete enrolments')
    "
      :canEditEnrolment="
      !!$store.getters['entities/user-permissions/find']('edit enrolments')
    "
      :canCreateSubject="
      !!$store.getters['entities/user-permissions/find']('create subjects')
    "
      :canDeleteSubject="
      !!$store.getters['entities/user-permissions/find']('delete subjects')
    "
      :canEditSubject="
      !!$store.getters['entities/user-permissions/find']('edit subjects')
    "
      :canCreateExtramural="
      !!$store.getters['entities/user-permissions/find']('create extramurals')
    "
      :canDeleteExtramural="
      !!$store.getters['entities/user-permissions/find']('delete extramurals')
    "
      :canEditExtramural="
      !!$store.getters['entities/user-permissions/find']('edit extramurals')
    "

      :canEditServices="
      !!$store.getters['entities/user-permissions/find']('edit users services')
    " :canForceServices="
      !!$store.getters['entities/user-permissions/find']('force unsubscribe users services')
    "

      :canCreate="
      !!$store.getters['entities/user-permissions/find']('create learners')
    "
      :canDelete="
      !!$store.getters['entities/user-permissions/find']('delete learners')
    "
      :canEdit="
      !!$store.getters['entities/user-permissions/find']('edit learners')
    "
      :canCreateGuardian="
      !!$store.getters['entities/user-permissions/find']('edit learners')
    "
      :canDeleteGuardian="
      !!$store.getters['entities/user-permissions/find']('edit learners')
    "
      :canEditGuardian="
      !!$store.getters['entities/user-permissions/find']('edit learners')
    "
      :canCreateWard="
      !!$store.getters['entities/user-permissions/find']('edit guardians')
    "
      :canDeleteWard="
      !!$store.getters['entities/user-permissions/find']('edit guardians')
    "
      :canEditWard="
      !!$store.getters['entities/user-permissions/find']('edit guardians')
    "
      :can-create-learner="
      !!$store.getters['entities/user-permissions/find']('create learners')
    "
  ></UserProfile>
</template>

<script>
import UserProfile from "@/components/users/UserProfile";
import User from "@/models/User";

export default {
  props: ["id"],
  components: {
    UserProfile,
  },
  data() {
    return {
      selected: false,
      user: null,
    };
  },

  methods: {
    handleDelete() {
      this.$router.push("/learners");
    },
  },
  mounted() {
    User.FetchById(this.id).then(({entities}) => {
      this.user = entities.users[0];
      this.selected = true;
    });
  },
};
</script>
